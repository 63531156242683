/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import './layout.css';
import styled from 'styled-components';
import FooterArea from './footer';
import HomeImage from './images/homeImage';
import { Link } from 'gatsby';

const Main = styled.main`
  flex: 1;
`;

const Header = styled.header`
  margin-top: 5px;
  margin-right: auto;
  margin-left: auto;
  a {
    display: inline-block;
    div {
      width: 200px;
      height: 88px;

      img {
        margin: 0;
        cursor: pointer;
      }
    }
  }
`;

const Layout = ({ children }) => {
  return (
    <>
      <Header>
        <Link to="/" aria-label="back">
          <HomeImage />
        </Link>
      </Header>
      <Main>{children}</Main>
      <FooterArea />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
