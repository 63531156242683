import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import styled from 'styled-components';
import RichText from '../components/richText';
import Layout from '../components/layout';

export const query = graphql`
  query ArticleQuery($id: String) {
    prismic {
      allArticles(id: $id) {
        edges {
          node {
            updated_at
            posted_at
            content
            article_title
            meta_description
            _meta {
              uid
              tags
              lang
            }
          }
        }
      }
    }
  }
`;

const Body = styled.div`
  margin: 0;
  display: flex;
  flex-flow: column;
  min-height: 100vh;
`;

const Section = styled.section`
  margin: 20px auto 60px auto;
  max-width: 680px;
  padding: 0 20px;
  line-height: 2;
  h1 {
    margin-bottom: 0.5rem;
  }
  a {
    color: #525efb;
    text-decoration: none;
    margin-bottom: 1.45rem;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
      color: orange;
    }
  }
  .day {
    margin-bottom: 0.5rem;
    .posted_at {
      margin-right: 1rem;
      font-size: 0.8rem;
    }
    .updated_at {
      margin-right: 1rem;
      font-size: 0.8rem;
    }
  }
`;

const Article = props => {
  const articleTitle =
    props.data.prismic.allArticles.edges[0].node.article_title;
  const postedAt = props.data.prismic.allArticles.edges[0].node.posted_at;
  const updatedAt = props.data.prismic.allArticles.edges[0].node.updated_at;
  const content = props.data.prismic.allArticles.edges[0].node.content;
  const metaDescription =
    props.data.prismic.allArticles.edges[0].node.meta_description[0].text;
  return (
    <Body>
      <SEO
        title={articleTitle[0].text}
        url={props.location.href}
        description={metaDescription}
      />
      <Layout>
        <Section>
          <RichText render={articleTitle} />
          <div className="day">
            <span className="posted_at">{postedAt}</span>
            {!!updatedAt && (
              <span className="updated_at">更新:{updatedAt}</span>
            )}
          </div>
          <RichText render={content} />
        </Section>
      </Layout>
    </Body>
  );
};

export default Article;
